import $api from '../axios.js';

async function SIGN_UP(payload){
  return $api.post('/user/signup', payload);
}

async function LOGIN(payload){
  return $api.post('/user/login', payload);
}

async function GET_USER(){
  return $api.get('/user');
}

async function LOGOUT(){
  return $api.get('/user/logout');
}

export {
  SIGN_UP,
  LOGIN,
  GET_USER,
  LOGOUT
}
