export default (breakpoints) => ({
  fontFamily: [
    'Rubik',
    'sans-serif'
  ].join(','),
  body1: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "1.7rem !important",
    [breakpoints.down('sm')]:{
      fontSize: "14px"
    }
  },
  h1: {
    fontSize: "64px",
    fontWeight: 500,
    [breakpoints.down('sm')]:{
      fontSize: "48px"
    },
    [breakpoints.down('xs')]:{
      fontSize: "38px"
    }
  },
  h2: {
    fontSize: "30px",
    fontWeight: 500,
    [breakpoints.down('sm')]:{
      fontSize: "28px"
    }
  },
  h3: {
    fontSize: "28px",
    fontWeight: 500,
    [breakpoints.down('sm')]:{
      fontSize: "24px"
    }
  },
  h4: {
    fontSize: "24px",
    fontWeight: 500,
    [breakpoints.down('sm')]:{
      fontSize: "20px"
    }
  },
  h5: {
    fontSize: "18px",
    fontWeight: 500,
    [breakpoints.down('sm')]:{
      fontSize: "14px"
    }
  }
})
