const React = require("react")
const AuthContext = require("./src/auth/context").default
const Auth = require("./src/auth").default
const SimpleReactLightbox = require("simple-react-lightbox").default
const auth = new Auth()

const Template = ({ element }) => {
  return (
    <>
      <AuthContext.Provider value={{ auth }}>
        <SimpleReactLightbox>
          {element}
        </SimpleReactLightbox>
      </AuthContext.Provider>
    </>
  )
}

exports.wrapRootElement = Template
