import { createTheme } from '@material-ui/core/styles';
import palette from "./palette.js";
import typography from "./typography.js"
import RubikRegular from '@fontsource/rubik/400.css';
import RubikBold from '@fontsource/rubik/500.css';

const { breakpoints } = createTheme({});

export default createTheme({
  palette,
  typography: typography(breakpoints),
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [RubikRegular, RubikBold],
      },
    },
  }
});
