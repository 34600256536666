import axios from "axios";
import Cookies from "js-cookie";
// export const baseURL = "http://localhost:3030/api";
// export const baseURL = "https://tsu-backend.herokuapp.com/api";
export const baseURL = "https://api.studentcenter.tsu.ru/api";

const $api = axios.create({
    baseURL,
    withCredentials: true
})

$api.interceptors.request.use(config => {
    if(localStorage.getItem("token"))
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
})

$api.interceptors.response.use(config => {
    return config;
}, async err => {
    const token = Cookies.get('refresh') || null;
    const originalRequest = err.config;
    if(err.response.status === 401 && err.config && !err.config._isRetry){
        originalRequest._isRetry = true;
        try{
            const { data } = await axios.post(`${baseURL}/user/login/token/refresh`, { token });
            localStorage.setItem('token', data.access_token)
            return $api.request(originalRequest)
        } catch (err){
            console.log(err)
        }
    }
    throw err;
});

export default $api;
