export default {
  primary: {
    light: "#ECF8FF",
    secondary: "#9EDCFF",
    main: "#0072BB"
  },
  white: {
    main: "#FFFFFF"
  },
  secondary: {
    main: "#686868"
  },
  error: {
    main: "#EB5757"
  },
  text: {
    primary: "#323232",
    muted: '#686868',
    warning: "#EB5757",
    black: '#000000',
    success: "#0072BB",
    navy_blue: "#002D4A",
    dark_blue: "#00304F",
    dark: "#002944",
  }
}
