import { LOGIN, SIGN_UP, GET_USER } from "@api/modules/auth";
import Cookies from 'js-cookie';
import axios from "axios";
import { baseURL } from '@api/axios';
import {makeAutoObservable} from "mobx";
import { LOGOUT } from "../api/modules/auth.js"

class Auth {
    constructor() {
        this.isAuth = false;
        this.user = {};
        this.isLoading = true;
        this.isRequestSend = false;
        this.isBrowser = typeof window !== "undefined";
        makeAutoObservable(this);
    }

    setUser(user){
        this.user = user;
    }

    setAuth(auth){
        this.isAuth = auth;
    }

    setLoading(state) {
        this.isLoading = state;
    }

    setRequestSend(state){
        this.isRequestSend = state;
    }

    async login(body){
        let status = true;
        let payload = {}
        try{
            const { data } = await LOGIN(body);
            localStorage.setItem('token', data.body.access_token);
            Cookies.set('refresh', data.body.refresh_token);
            this.setAuth(true);
            this.setUser(data.body);
        } catch (err) {
            status = false;
            payload = err.response.data;
        }
        return { status, payload }
    }

    async signup(body){
        let status = true;
        let payload = {}
        try{
            const { data } = await SIGN_UP(body);
            localStorage.setItem('token', data.body.access_token);
            Cookies.set('refresh', data.body.refresh_token);
            this.setAuth(true);
            this.setUser(data.body);
        } catch (err) {
            status = false;
            payload = err.response.data;
        }
        return { status, payload }
    }

    async logout(){
        try{
            await LOGOUT();
            localStorage.removeItem("token");
            Cookies.remove('refresh');
            this.setAuth(false)
            this.setUser({});
        } catch (e) {
            console.log(e)
        }
    }

    async checkAuth(){
        this.setLoading(true)
        this.setRequestSend(true);
        try{
            const { data } = await GET_USER();
            this.setAuth(true);
            this.setUser(data);
        } catch (err){
            console.log(err)
        } finally {
            setTimeout( () => this.setLoading(false), 500);
        }
    }
}

export default Auth;
